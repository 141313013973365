<template>
  <div class="unlink">
    <MobileTable
      v-model:selection="store.state.unlink.selectedUnlinkList"
      :label="$route.name"
      :columns="unlink.columns"
      :data="store.state.unlink.unlinkList"
    />
    <DesktopTable
      v-model:selection="store.state.unlink.selectedUnlinkList"
      :label="$route.name"
      :columns="unlink.columns"
      :data="store.state.unlink.unlinkList"
    />
    <Actions :items="unlink.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/desvinculaciones/nuevo");
    };
    const onDownload = async () => {
      if (store.state.unlink.selectedUnlinkList.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        //TODO: descargar
      }
    };

    const unlink = reactive({
      columns: [
        {
          field: "personnel",
          header: "Nombre del Personal",
          sort: true,
        },
        {
          field: "updatedAt",
          header: "Fecha de carga",
          sort: true,
          date: true,
        },
        {
          field: "updatedUser",
          header: "Cargado por Usuario",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/desvinculaciones/nuevo"),
        },
        {
          name: "Descargar",
          action: onDownload,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/desvinculaciones/listado"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedUnlinkList", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.unlinkActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, unlink };
  },
};
</script>

<style scoped></style>
